// Dependencies - Vendor
import { createI18n } from 'vue-i18n';

// Dependencies - Framework
import '@/assets/main.css';
import messages from '@intlify/unplugin-vue-i18n/messages';

// Interfaces/Types - Component Custom Properties
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $n(value: number, key?: string): string;
        $t(id: string, params?: (string | number)[]): string;
    }
}

// Constants
const NUMBER_FORMATS = {
    en: {
        fixed1: { maximumFractionDigits: 1, minimumFractionDigits: 1, useGrouping: true },
        fixed2: { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: true },
        whole: { maximumFractionDigits: 0, useGrouping: true }
    },
    es: {
        fixed1: { maximumFractionDigits: 1, minimumFractionDigits: 1, useGrouping: true },
        fixed2: { maximumFractionDigits: 2, minimumFractionDigits: 2, useGrouping: true },
        whole: { maximumFractionDigits: 0, useGrouping: true }
    }
};
const SUPPORTED_LOCALE_IDS: string[] = ['en', 'es'];

// Utilities - Establish Preferred Language Identifier
const establishPreferredLocaleId = (): string => {
    // Check for exact language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.toLowerCase();
        if (SUPPORTED_LOCALE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    // Check for neutral language match.
    for (const languageId of window.navigator.languages) {
        const transformedLanguageId = languageId.split('-')[0].toLowerCase();
        if (SUPPORTED_LOCALE_IDS.includes(transformedLanguageId)) {
            return transformedLanguageId;
        }
    }
    return 'en'; // Default to english.
};

// Initialise
const i18nOptions = {
    availableLocales: SUPPORTED_LOCALE_IDS,
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false,
    locale: establishPreferredLocaleId(),
    messages,
    numberFormats: NUMBER_FORMATS
};
export default createI18n(i18nOptions);
