<script lang="ts" setup>
// Dependencies - Vendor Instances
import { computed } from 'vue';

// Dependencies - Framework Types
import type { DrawerStateId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { actionIcons } from '@/assets/icons';
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Dependencies - Component Instances
import DataActions from '@/views/toolMenu/DataActions.vue';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import HorizontalTransition from '@/components/HorizontalTransition.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';
import ToolTabs from '@/views/toolMenu/ToolTabs.vue';
import UIActions from '@/views/toolMenu/UIActions.vue';

// Global State
const workbenchStore = useWorkbenchStore();

// Reactive Variables & Watchers
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.optionDrawerStateId);
const toolDrawerPanelId = computed<string | undefined>((): string | undefined => workbenchStore.toolDrawerPanelId);
const toolDrawerWidth = computed<number>((): number => workbenchStore.toolDrawerWidth);
const toolMenuDropdownIsOpen = computed<boolean>((): boolean => workbenchStore.toolMenuDropdownIsOpen);
const toolMenuWidth = computed<number>((): number => workbenchStore.toolMenuWidth);

// UI Event Handlers - Toggle
const handelToggle = (): void => {
    workbenchStore.collapseOptionDrawer();
    workbenchStore.toggleToolComponents();
};
</script>

<template>
    <div>
        <!-- Expanded Tool Menu -->
        <div class="h-14 items-center bg-surface-light dark:bg-surface-dark" :class="optionDrawerStateId === 'expanded' ? 'flex' : 'hidden'">
            <!-- Actions -->
            <div class="flex items-center gap-x-2 pl-2">
                <DataActions />
                <div class="mx-0.5 h-6 w-px bg-separator-light dark:bg-separator-dark" />
                <UIActions />
            </div>

            <!-- Tool Tabs -->
            <ToolTabs class="pr-4" :class="`${toolDrawerPanelId ? 'border-separator-light dark:border-separator-dark' : ''}`" />
        </div>

        <!-- Contracted Tool Menu -->
        <div
            class="max-w-[calc(100%)] bg-surface-light dark:bg-surface-dark"
            :class="optionDrawerStateId === 'expanded' ? 'hidden' : 'flex flex-col'"
            :style="{ width: `${Math.max(toolMenuWidth, toolDrawerWidth)}px` }"
        >
            <div
                class="flex h-14 max-w-full items-center justify-end border-l pl-4"
                :class="`${toolMenuDropdownIsOpen || toolDrawerPanelId ? 'border-separator-light dark:border-separator-dark' : 'border-transparent dark:border-transparent'}`"
                :style="{ width: `${toolDrawerPanelId ? `${toolDrawerWidth}px` : 'unset'}` }"
            >
                <!-- Tool Tabs -->
                <HorizontalTransition appear>
                    <ToolTabs v-if="toolMenuDropdownIsOpen || toolDrawerPanelId" class="overflow-x-scroll pr-2" />
                </HorizontalTransition>

                <!-- Toggle -->
                <SimpleButton class="mr-4" kind="option.clear" square @click.stop="handelToggle">
                    <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleSVG" />
                </SimpleButton>
            </div>

            <!-- Actions -->
            <HorizontalTransition appear>
                <div v-if="toolMenuDropdownIsOpen" class="rounded-bl-md border-b border-l border-separator-light px-4 dark:border-divider-dark">
                    <HorizontalDivider />
                    <div class="flex gap-x-2 py-2">
                        <DataActions />
                    </div>
                    <HorizontalDivider />
                    <div class="flex gap-x-2 py-2">
                        <UIActions />
                    </div>
                </div>
            </HorizontalTransition>
        </div>
    </div>
</template>
