<script lang="ts" setup>
// Dependencies - Vendor Instances
import { computed } from 'vue';

// Dependencies - Framework Types
import type { DrawerStateId, WidthId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { actionIcons, authIcons } from '@/assets/icons';
import { useInternetStore } from '@/stores/internetStore';
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Dependencies - Component Instances
import SimpleButton from '@/components/SimpleButton.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Interfaces/Types - Tool Panel Identifier
export type ToolPanelId = 'about' | 'account' | 'chat' | 'documentation' | 'index';

// Global State
const internetStore = useInternetStore();
const workbenchStore = useWorkbenchStore();

// Reactive Variables & Watchers
const internetConnectionIsOnline = computed<boolean | undefined>((): boolean | undefined => internetStore.internetConnectionIsOnline);
const toolDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.toolDrawerStateId);
const toolDrawerPanelId = computed<string | undefined>((): string | undefined => workbenchStore.toolDrawerPanelId);
const toolDrawerWidthId = computed<WidthId>((): WidthId => workbenchStore.toolDrawerWidthId);

// UI Event Handlers - Select Tab
const handleSelectAboutTab = (): void => selectTab('about');
const handleSelectAccountTab = (): void => selectTab('account');
const handleSelectChatTab = (): void => selectTab('chat');
const handleSelectDocumentationTab = (): void => selectTab('documentation');

// UI Event Handlers - Toggle Resize
const handleToggleResize = (): void => {
    workbenchStore.resizeToolComponents();
};

// Utilities - Get Tab Color
const getTabColor = (tabId: ToolPanelId): string => {
    const activeTabColor = toolDrawerPanelId.value === tabId ? 'border-highlight-light dark:border-highlight-dark' : 'border-divider-light dark:border-divider-dark';
    return toolDrawerPanelId.value ? activeTabColor : 'border-transparent dark:border-transparent';
};

// Utilities - Select Tab
const selectTab = (toolPanelId: ToolPanelId): void => {
    workbenchStore.handleToolComponentsMenuTabChange(toolPanelId);
    workbenchStore.toolMenuDropdownIsOpen = false;
};
</script>

<template>
    <div class="ml-2 flex h-full w-full items-center justify-end gap-x-2">
        <!-- Expand/Collapse Action -->
        <!-- <div v-if="toolDrawerPanelId && toolDrawerStateId !== 'floating'" class="border-b-2 border-b-transparent pt-2 dark:border-b-transparent">
            <SimpleButton class="flex items-center justify-center" kind="option.clear" @click="handleToggleResize">
                <SVGIcon class="h-9 w-9 origin-center rotate-45" :svg="toolDrawerWidthId === 'wide' ? actionIcons.collapseSVG : actionIcons.expandSVG" />
            </SimpleButton>
        </div> -->

        <!-- Vertical Divider -->
        <div v-if="toolDrawerStateId !== 'floating'" class="mx-0.5 h-6 w-px bg-separator-light dark:bg-separator-dark" />

        <!-- Tabs -->
        <div class="flex h-full gap-x-2">
            <!-- Documentation Action -->
            <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('documentation')">
                <SimpleButton kind="option.clear" @click="handleSelectDocumentationTab">
                    <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleDocumentationSVG" />
                </SimpleButton>
            </div>

            <!-- Communication Action -->
            <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('chat')">
                <SimpleButton kind="option.clear" @click="handleSelectChatTab">
                    <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleChatSVG" />
                </SimpleButton>
            </div>

            <!-- Account Action -->
            <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('account')">
                <SimpleButton kind="option.clear" @click="handleSelectAccountTab">
                    <SVGIcon class="h-9 w-9" :svg="authIcons.manageAccountSVG" />
                </SimpleButton>
            </div>

            <!-- About Action -->
            <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('about')">
                <SimpleButton class="relative" kind="option.clear" @click="handleSelectAboutTab">
                    <div class="flex h-9 w-9 flex-none items-center justify-center">
                        <img class="h-6 w-6" src="/dataPos.svg" />
                    </div>

                    <StatusBadge v-if="!internetConnectionIsOnline" class="absolute -right-0.5 -top-0.5 z-10" color="negative" />
                </SimpleButton>
            </div>
        </div>
    </div>
</template>
