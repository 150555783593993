// Dependencies - Vendor Instances
import { defineStore } from 'pinia';
import { nanoid } from 'nanoid';
import { ref } from 'vue';

// Dependencies - Component Types
import type { AlertConfig } from '@/components/AlertBox.vue';

// Alert Store - Compose & Expose
export const useAlertStore = defineStore('alert', () => {
    // Properties & Variables
    const alertConfigs = ref<AlertConfig[]>([]); // This cannot be shallow as individual timers are added and removed as required.

    // Methods - Show Alert
    const showAlert = (alertConfig: AlertConfig): void => {
        alertConfigs.value.push({ ...alertConfig, id: nanoid() });
    };

    // Exposures
    return {
        // Properties
        alertConfigs,

        // Actions
        showAlert
    };
});
