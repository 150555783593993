<script lang="ts" setup>
// Dependencies - Vendor Instances
import { animate } from 'motion/mini';
import { nextTick } from 'vue';
import { useResizeObserver } from '@vueuse/core';

// Attributes, Emitted Events, Options, Properties & Slots
const { appear = false } = defineProps<{ appear?: boolean }>();
const emit = defineEmits<(event: 'resize', width: number) => void>();
defineSlots<{ default(): unknown }>();

// Constants
const TRANSITION_SPEED_PER_PX_IN_MS = 0.3 / 320; // Based on a medium width drawer (320px) transitioning in 0.3 seconds.

// UI Event Handlers - Enter
const handleEnter = async (element: Element, done: () => void): Promise<void> => {
    (element as HTMLElement).style.overflow = 'hidden';
    // useResizeObserver(element as HTMLElement, (entries): void => {
    //     emit('resize', entries[0].contentRect.width);
    // });
    await nextTick();
    const width = (element as HTMLElement).scrollWidth;
    (element as HTMLElement).style.maxWidth = `${width}px`;
    const duration = width * TRANSITION_SPEED_PER_PX_IN_MS;
    await animate(element as HTMLElement, { maxWidth: ['0', `${width}px`], opacity: [0, 1] }, { duration, ease: 'linear' });
    done();
};

// UI Event Handlers - After Enter
const handleAfterEnter = (element: Element): void => {
    (element as HTMLElement).style.maxWidth = '';
    (element as HTMLElement).style.overflow = '';
};

// UI Event Handlers - Leave
const handleLeave = async (element: Element, done: () => void): Promise<void> => {
    (element as HTMLElement).style.overflow = 'hidden';
    await nextTick();
    const width = (element as HTMLElement).scrollWidth;
    (element as HTMLElement).style.maxWidth = '0';
    const duration = width * TRANSITION_SPEED_PER_PX_IN_MS;
    await animate(element as HTMLElement, { maxWidth: [`${width}px`, '0'], opacity: [1, 0] }, { duration, ease: 'linear' });
    done();
};

// UI Event Handlers - After Leave
const handleAfterLeave = (element: Element): void => {
    (element as HTMLElement).style.maxWidth = '';
    (element as HTMLElement).style.overflow = '';
};
</script>

<template>
    <Transition :appear="appear" :css="false" @enter="handleEnter" @after-enter="handleAfterEnter" @leave="handleLeave" @after-leave="handleAfterLeave">
        <slot />
    </Transition>
</template>
