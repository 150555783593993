<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework Types
import type { WidthId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Global State
const workbenchStore = useWorkbenchStore();

// Reactive Variables & Watchers - Simple
const workbenchBreadcrumbs = computed<string[]>((): string[] => workbenchStore.breadcrumbs || ['Home']);
const workbenchTitle = computed<string>((): string => workbenchStore.title || 'Workbench');
const workbenchWindowWidthId = computed<WidthId>((): WidthId => workbenchStore.windowWidthId);
</script>

<template>
    <div class="overflow-hidden transition-[left] duration-300 ease-in-out">
        <div class="flex items-center" :class="workbenchWindowWidthId === 'wide' ? 'ml-4 translate-x-0' : 'translate-x-1/2'">
            <div class="flex h-14 max-w-full flex-col justify-center" :class="workbenchWindowWidthId === 'wide' ? 'translate-x-0 text-left' : '-translate-x-1/2 text-center'">
                <!-- Title -->
                <div class="truncate">{{ workbenchTitle }}</div>

                <!-- Breadcrumbs -->
                <div class="truncate text-sm text-caption-light dark:text-caption-dark">{{ workbenchBreadcrumbs[0] }}</div>
            </div>
        </div>
    </div>
</template>
