// Dependencies - Vendor Instances
import { defineStore } from 'pinia';
import { shallowRef } from 'vue';

// Dependencies - Framework Instances
import { LOCAL_STORAGE } from '@/globals';

// Interfaces/Types - Appearance
export interface Appearance {
    id: AppearanceId;
    isDark?: boolean;
    nextId: AppearanceId;
}

// Interfaces/Types - Appearance Identifier
type AppearanceId = 'dark' | 'light' | 'system';

// Appearance Store - Compose & Expose
export const useAppearanceStore = defineStore('appearance', () => {
    // Utilities - Build Initial Appearance
    const buildInitialAppearance = (id: AppearanceId): Appearance => {
        switch (id) {
            case 'dark':
                return { id, isDark: true, nextId: 'system' };
            case 'light':
                return { id, isDark: false, nextId: 'dark' };
            default: {
                const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                return { id, isDark, nextId: 'light' };
            }
        }
    };

    // Properties & Variables
    const appearance = shallowRef<Appearance>(buildInitialAppearance((window.localStorage.getItem(LOCAL_STORAGE.APPEARANCE.ID) || 'system') as AppearanceId));

    // Methods - Apply Appearance Using Id
    const applyAppearanceUsingId = (id: AppearanceId): void => {
        switch (id) {
            case 'dark':
                window.document.documentElement.classList.add('dark');
                appearance.value = { id, isDark: true, nextId: 'system' };
                break;
            case 'light':
                window.document.documentElement.classList.remove('dark');
                appearance.value = { id, isDark: false, nextId: 'dark' };
                break;
            default: {
                const isDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
                applyAppearanceUsingMode(isDark);
                appearance.value = { id, isDark, nextId: 'light' };
                break;
            }
        }
        localStorage.setItem(LOCAL_STORAGE.APPEARANCE.ID, id);
    };

    // Methods - Apply Appearance Using Mode
    const applyAppearanceUsingMode = (isDark: boolean): void => {
        if (isDark) {
            window.document.documentElement.classList.add('dark');
            return;
        }
        window.document.documentElement.classList.remove('dark');
    };

    // UI Event Handlers - Watch For System Appearance Change
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event): void => {
        if (appearance.value.id !== 'system') {
            return;
        }
        applyAppearanceUsingMode(event.matches);
    });

    // Exposures
    return {
        // Properties
        appearance,

        // Actions
        applyAppearanceUsingId
    };
});
