<script lang="ts" setup>
// Dependencies - Vendor Instances
import { computed, onErrorCaptured, shallowRef } from 'vue';
import { useRouter } from 'vue-router';

// Dependencies - Framework Types
import type { BenchtopConfig, OptionConfig, WorkbenchConfig } from '@/views/WorkbenchConfig';
import type { DrawerStateId, WidthId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { actionIcons, optionIcons } from '@/assets/icons';
import { DRAWER_MINIMUM_WIDTH, useWorkbenchStore } from '@/stores/workbenchStore';
import { handleError } from '@/globals';
import { workbenchConfigData } from '@/views/WorkbenchConfig';

// Dependencies - Component Instances
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const workbenchStore = useWorkbenchStore();
const router = useRouter();

// Constants
const WORKFLOW_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const benchtopConfig = shallowRef<BenchtopConfig>(WORKFLOW_CONFIG.benchtops[2]);
const optionDrawerMenuWidth = computed<number>((): number => workbenchStore.optionDrawerMenuWidth);
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.optionDrawerStateId);
const workbenchWindowWidthId = computed<WidthId>((): WidthId => workbenchStore.windowWidthId);

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => {
    return handleError(error, { locator: 'OptionMenu.onErrorCaptured.1' });
});

// UI Event Handlers - Toggle Option Drawer
const handleToggleOptionDrawer = (): void => {
    if (workbenchWindowWidthId.value === 'narrow') workbenchStore.collapseToolComponents();
    workbenchStore.toggleOptionDrawer();
};

// UI Event Handlers - Navigate To Benchtop
const handleNavigateToBenchtop = async (index: number): Promise<void> => {
    benchtopConfig.value = WORKFLOW_CONFIG.benchtops[index];
    await handleNavigateToOption(WORKFLOW_CONFIG.benchtops[index].options[0], 'home');
};

// UI Event Handlers - Navigate To Option
const handleNavigateToOption = async (optionConfig: OptionConfig, optionId: string): Promise<void> => {
    workbenchStore.optionDrawerPanelId = optionId;
    if (optionDrawerStateId.value === 'floating') {
        workbenchStore.collapseOptionDrawer();
    }
    await router.push({ path: optionConfig.to });
};

// Utilities - Get Tab Color
const getTabColor = (tabId: string): string => {
    return benchtopConfig.value.id === tabId ? 'border-highlight-light dark:border-highlight-dark' : 'border-divider-light dark:border-divider-dark';
};
</script>

<template>
    <div class="flex max-w-[calc(100%-16px)] items-center justify-between px-4 transition-[width] duration-300 ease-in-out">
        <!-- Toggle Button -->
        <SimpleButton kind="option.clear" @click="handleToggleOptionDrawer">
            <SVGIcon class="h-9 w-9" :svg="actionIcons.toggleSVG" />
        </SimpleButton>

        <!-- Benchtop Tabs -->
        <Transition appear name="fade-with-delay">
            <div v-if="optionDrawerMenuWidth > DRAWER_MINIMUM_WIDTH" class="flex h-full justify-end gap-x-2 overflow-hidden">
                <!-- Administrator Tab -->
                <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('administrator')">
                    <SimpleButton kind="option.clear" @click="handleNavigateToBenchtop(0)">
                        <SVGIcon class="h-9 w-9" :svg="optionIcons.administrator_homeSVG" />
                    </SimpleButton>
                </div>

                <!-- Partner Tab -->
                <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('partner')">
                    <SimpleButton kind="option.clear" @click="handleNavigateToBenchtop(1)">
                        <SVGIcon class="h-9 w-9" :svg="optionIcons.partner_homeSVG" />
                    </SimpleButton>
                </div>

                <!-- Workflow Tab -->
                <div class="flex h-full items-center border-b-2 pt-0.5" :class="getTabColor('workflow')">
                    <SimpleButton kind="option.clear" @click="handleNavigateToBenchtop(2)">
                        <SVGIcon class="h-9 w-9" :svg="optionIcons.workflow_homeSVG" />
                    </SimpleButton>
                </div>
            </div>
        </Transition>
    </div>
</template>
