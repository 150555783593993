<script lang="ts" setup>
// Dependencies - Vendor Instances
import { computed, onErrorCaptured, onMounted, ref, watchEffect } from 'vue';
import { RouterView } from 'vue-router';
import { useResizeObserver } from '@vueuse/core';

// Dependencies - Framework Types
import type { DrawerStateId, WidthId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { DRAWER_MINIMUM_WIDTH, useWorkbenchStore } from '@/stores/workbenchStore';
import { handleError } from '@/globals';
import { useAlertStore } from '@/stores/alertStore';

// Dependencies - Component Types
import type { AlertConfig } from '@/components/AlertBox.vue';

// Dependencies - Component Instances
import AlertBox from '@/components/AlertBox.vue';
import DefaultMask from '@/components/DefaultMask.vue';
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import HorizontalTransition from '@/components/HorizontalTransition.vue';
import LoadingMask from '@/components/LoadingMask.vue';
import OptionDrawer from '@/views/optionDrawer/OptionDrawer.vue';
import OptionMenu from '@/views/optionMenu/OptionMenu.vue';
import ToolDrawer from '@/views/toolDrawer/ToolDrawer.vue';
import ToolMenu from '@/views/toolMenu/ToolMenu.vue';
import ViewHeader from '@/components/ViewHeader.vue';

// Global State
const alertStore = useAlertStore();
const workbenchStore = useWorkbenchStore();

// Reactive Element References
const toolMenuRef = ref<HTMLDivElement | null>(null);

// Reactive Variables & Watchers - Primary
const alertConfigs = computed<AlertConfig[]>((): AlertConfig[] => alertStore.alertConfigs);
const navigationDepth = computed<number>((): number => workbenchStore.navigationDepth);
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.optionDrawerStateId);
const optionDrawerWidth = computed<number>((): number => workbenchStore.optionDrawerWidth);
const optionDrawerMenuWidth = computed<number>((): number => workbenchStore.optionDrawerMenuWidth);
const toolDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.toolDrawerStateId);
const toolDrawerWidth = computed<number>((): number => workbenchStore.toolDrawerWidth);
const toolMenuWidth = ref<number>(0);
const viewHeaderStyle = ref<Record<string, string>>();
const workbenchMaskIsActive = computed<boolean>((): boolean => workbenchStore.maskIsActive);
const workbenchModuleIsLoading = computed<boolean>((): boolean => workbenchStore.moduleIsLoading);
const workbenchWindowWidthId = computed<WidthId>((): WidthId => workbenchStore.windowWidthId);

// Reactive Variables & Watchers - Secondary
const toolDrawerResizeWidth = ref<number>(workbenchStore.toolDrawerWidth - 2);
watchEffect(() => {
    if (optionDrawerStateId.value === 'floating') {
        viewHeaderStyle.value = { left: `${DRAWER_MINIMUM_WIDTH}px`, width: `calc(100% - ${DRAWER_MINIMUM_WIDTH + toolMenuWidth.value}px)` };
        return;
    }
    if (workbenchWindowWidthId.value === 'narrow') {
        viewHeaderStyle.value = { left: `${optionDrawerMenuWidth.value}px`, width: `calc(100% - ${optionDrawerMenuWidth.value + DRAWER_MINIMUM_WIDTH}px)` };
        return;
    }
    viewHeaderStyle.value = { left: `${optionDrawerMenuWidth.value}px`, width: `calc(100% - ${optionDrawerMenuWidth.value + toolMenuWidth.value}px)` };
});

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => handleError(error, { locator: 'Workbench.1' }));
onMounted((): void => {
    useResizeObserver(toolMenuRef.value, (entries): void => {
        toolMenuWidth.value = entries[0].contentRect.width;
    });
});

// UI Event Handlers - Mask Clicked
const handleMaskClicked = (): void => {
    if (workbenchStore.optionDrawerStateId === 'floating') {
        workbenchStore.collapseOptionDrawer();
    }
    if (workbenchStore.toolDrawerStateId === 'floating') {
        workbenchStore.collapseToolComponents();
    }
};

// UI Event Handlers - Tool Drawer Resize
const handleToolDrawerResize = (width: number): void => {
    toolDrawerResizeWidth.value = width;
};

// UI Event Handlers - Navigate Back
const handleNavigateBack = () => {
    window.history.back(); // TODO: Should this be 'router.back()'?
};
</script>

<template>
    <!-- Layers -->
    <!-- z-10: Workbench Component. -->
    <!-- z-20: View Header. -->
    <!-- z-30: Option Menu & Tool Menu when the other menu is floating. This ensures the non-floating menu is behind the Floating Drawer Mask. -->
    <!-- z-40: Floating Drawer Mask. -->
    <!-- z-50: Option Drawer & Tool Drawer. -->
    <!-- z-60: Option Menu & Tool Menu when associated drawer is floating. -->
    <!-- z-70: Workbench Mask. -->
    <!-- z-80: Alert Box. -->

    <!-- Workbench -->
    <div class="relative h-full">
        <!-- Option Menu - Positioned at top to the left. -->
        <OptionMenu class="fixed left-0 top-0 h-14" :class="toolDrawerStateId === 'floating' ? 'z-30' : 'z-60'" :style="{ width: `${optionDrawerMenuWidth - 1}px` }" />

        <!-- View Header - Position at top between option menu and tool menu. -->
        <ViewHeader class="fixed z-20" :class="navigationDepth > 0 ? 'cursor-pointer' : ''" :style="viewHeaderStyle" @click="navigationDepth > 0 ? handleNavigateBack : undefined" />

        <!-- Tool Menu - Positioned at top to the right. -->
        <ToolMenu ref="toolMenuRef" class="fixed right-0 top-0 h-14 max-w-[calc(100%-16px)]" :class="optionDrawerStateId === 'floating' ? 'z-30' : 'z-60'" />

        <!-- Divider - Immediately below header. -->
        <HorizontalDivider class="fixed right-4 top-14" :style="{ left: workbenchWindowWidthId === 'wide' ? `${optionDrawerWidth + 16}px` : '16px' }" />

        <!-- Body - Fills entire window. -->
        <div class="flex h-full w-full">
            <!-- Option Drawer - Positioned on left side. -->
            <OptionDrawer
                class="dark_bg-surface-dark h-full flex-none bg-surface-light pt-14"
                :class="optionDrawerStateId === 'floating' ? 'absolute left-0 top-0 z-50' : 'z-50'"
                :style="{ width: `${optionDrawerWidth}px` }"
            />

            <!-- Workbench Component - Positioned in center between drawers, when they are not floating, and behind tool menu. -->
            <div class="relative z-10 h-full flex-1 pt-[57px]" :style="{ width: `calc(100% - ${optionDrawerWidth + toolDrawerWidth}px` }">
                <RouterView v-slot="{ Component }">
                    <Transition appear name="fade">
                        <LoadingMask v-if="workbenchModuleIsLoading" />
                        <component :is="Component" v-else-if="Component" />
                    </Transition>
                </RouterView>
            </div>

            <!-- Tool Drawer - Position on right side below tool menu. -->
            <HorizontalTransition appear @resize="handleToolDrawerResize">
                <ToolDrawer
                    v-if="toolDrawerWidth !== 0"
                    class="transition-width flex-none duration-300 ease-in-out"
                    :class="toolDrawerStateId === 'floating' ? 'absolute right-0 top-0 z-50' : 'z-50'"
                    :style="{ width: `${toolDrawerWidth}px` }"
                />
            </HorizontalTransition>
        </div>
    </div>

    <!-- Drawer Mask - Only displayed when the option drawer is floating or the tool drawer is open. -->
    <Transition name="fade">
        <DefaultMask v-if="optionDrawerStateId === 'floating' || toolDrawerStateId === 'floating'" class="z-40" is-opaque @click="handleMaskClicked" />
    </Transition>

    <!-- Workbench Mask - Sits above all elements except the alert box scroller. -->
    <Transition name="fade">
        <DefaultMask v-if="workbenchMaskIsActive" key="default-mask-2" class="z-70" is-opaque />
    </Transition>

    <!-- Alerts - See '#alertBoxScroller' in 'index.html' with 'z-index: 80'. -->
    <Teleport to="#alertBoxScroller">
        <template v-for="alertConfig in alertConfigs" :key="alertConfig.id">
            <AlertBox :config="alertConfig" />
        </template>
    </Teleport>
</template>
