<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';
import { useFullscreen } from '@vueuse/core';

// Dependencies - Framework
import type { Appearance } from '@/stores/appearanceStore';

import { actionIcons } from '@/assets/icons';
import { useAppearanceStore } from '@/stores/appearanceStore';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const appearanceStore = useAppearanceStore();

// Reactive Variables & Watchers
const appearance = computed<Appearance>((): Appearance => appearanceStore.appearance);
const { isFullscreen, isSupported: isFullscreenSupported, toggle: toggleFullscreen } = useFullscreen();

// UI Event Handlers - Toggle Appearance
const handleToggleAppearance = (): void => {
    switch (appearance.value.id) {
        case 'dark':
            return appearanceStore.applyAppearanceUsingId('system');
        case 'light':
            return appearanceStore.applyAppearanceUsingId('dark');
        default:
            return appearanceStore.applyAppearanceUsingId('light');
    }
};
</script>

<template>
    <div class="flex gap-x-2">
        <!-- Toggle Fullscreen Action -->
        <SimpleButton :disabled="!isFullscreenSupported" kind="option.clear" @click="toggleFullscreen">
            <SVGIcon class="h-9 w-9" :svg="isFullscreen ? actionIcons.exitFullscreenSVG : actionIcons.enterFullscreenSVG" />
        </SimpleButton>

        <!-- Toggle Appearance Action -->
        <SimpleButton kind="option.clear" @click="handleToggleAppearance">
            <SVGIcon class="h-9 w-9" :svg="actionIcons[`appear${appearance.nextId.charAt(0).toUpperCase()}${appearance.nextId.slice(1)}SVG`]" />
        </SimpleButton>
    </div>
</template>
