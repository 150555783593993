// Dependencies - Vendor Instances
import { ref, shallowRef } from 'vue';
import { defineStore } from 'pinia';

// Dependencies - Framework Types
import type { ConnectionConfig } from '@datapos/datapos-share-core';

// Interfaces/Types - Authorisation Flow  UI (Input Error, State)
export interface AuthFlowUIInputError {
    error: {
        code: string;
        message: string;
    };
    max_length?: number;
    min_length?: number;
    name?: string;
    required?: boolean;
    type?: string;
    value?: string;
}
export type AuthFlowUIState = 'chooseMethod' | 'enterPasscode' | 'enterPassword' | 'enterHandle' | 'manageAccess' | 'manageAccount' | 'reauthorise' | 'signIn' | 'signUp';

// Interfaces/Types - User
export interface User {
    id: string;
    emailAddress?: string;
    key?: string;
}

// Session Store - Compose & Expose
export const useSessionStore = defineStore('session', () => {
    // Properties & Variables
    const authFlowUIInputErrors = shallowRef<AuthFlowUIInputError[]>([]);
    const authFlowUIState = ref<AuthFlowUIState | undefined>(undefined);
    const connectionConfigs = shallowRef<ConnectionConfig[]>([]);
    const sessionExpiresIn = ref<number>(0);
    const sessionIsReauthorising = ref<boolean>(false);
    const sessionLifetime = ref<number>(0);
    const sessionUpdatesArePending = ref<boolean>(false);
    const sessionUser = shallowRef<User | false | undefined>(undefined);

    // Exposures
    return {
        // Properties
        authFlowUIInputErrors,
        authFlowUIState,
        connectionConfigs,
        sessionExpiresIn,
        sessionIsReauthorising,
        sessionLifetime,
        sessionUpdatesArePending,
        sessionUser
    };
});
