<script lang="ts" setup>
// Dependencies - Vendor Instances
import { computed, onErrorCaptured, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Dependencies - Framework Types
import type { BenchtopConfig, OptionConfig, WorkbenchConfig } from '@/views/WorkbenchConfig';
import type { DrawerStateId, WidthId } from '@/stores/workbenchStore';

// Dependencies - Framework Instances
import { DRAWER_MINIMUM_WIDTH, useWorkbenchStore } from '@/stores/workbenchStore';
import { handleError } from '@/globals';
import { optionIcons } from '@/assets/icons';
import { workbenchConfigData } from '@/views/WorkbenchConfig';

// Dependencies - Component Instances
import HorizontalDivider from '@/components/HorizontalDivider.vue';
import HorizontalTransition from '@/components/HorizontalTransition.vue';
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Global State
const workbenchStore = useWorkbenchStore();
const router = useRouter();
const route = useRoute();

// Constants
const ROUTE_SEGMENTS: string[] = route.path.split('/');
const WORKBENCH_CONFIG: WorkbenchConfig = workbenchConfigData;

// Reactive Variables & Watchers
const benchtopConfig = shallowRef<BenchtopConfig>(WORKBENCH_CONFIG.benchtops[2]);
const labelsAreVisible = computed<boolean>((): boolean => optionDrawerStateId.value === 'floating' || (optionDrawerStateId.value === 'expanded' && optionDrawerWidthId.value === 'wide'));
const optionDrawerPanelId = computed<string | undefined>((): string | undefined => workbenchStore.optionDrawerPanelId);
const optionDrawerStateId = computed<DrawerStateId>((): DrawerStateId => workbenchStore.optionDrawerStateId);
const optionDrawerMenuWidth = computed<number>((): number => workbenchStore.optionDrawerMenuWidth);
const optionDrawerWidthId = computed<WidthId>((): WidthId => workbenchStore.optionDrawerWidthId);

// Initialisation
workbenchStore.optionDrawerPanelId = ROUTE_SEGMENTS.length > 2 ? ROUTE_SEGMENTS[2] : 'home';

// Lifecycle Event Handlers
onErrorCaptured((error): boolean => handleError(error, { locator: 'OptionDrawer.onErrorCaptured.1' }));

// UI Event Handlers - Navigate To Option
const handleNavigateToOption = async (optionConfig: OptionConfig, optionId: string): Promise<void> => {
    workbenchStore.optionDrawerPanelId = optionId;
    if (optionDrawerStateId.value === 'floating') {
        workbenchStore.collapseOptionDrawer();
    }
    await router.push({ path: optionConfig.to });
};
</script>

<template>
    <HorizontalTransition appear>
        <div v-if="optionDrawerStateId !== 'collapsed'" class="flex max-w-[calc(100%-16px)] flex-col border-r transition-[width] duration-300 ease-in-out">
            <!-- Divider -->
            <HorizontalDivider class="mx-4" />

            <!-- Options Panel -->
            <div class="flex flex-1 flex-col overflow-y-scroll overscroll-y-none px-4 pb-10">
                <template v-for="optionConfig in benchtopConfig.options" :key="optionConfig.id">
                    <HorizontalDivider v-if="optionConfig.isNewGroup" class="mt-2 flex-none" />
                    <SimpleButton
                        class="relative mt-2 flex-none gap-x-2"
                        :data-selected="optionConfig.id === optionDrawerPanelId"
                        kind="option.clear"
                        @click="() => handleNavigateToOption(optionConfig, optionConfig.id)"
                    >
                        <SVGIcon class="h-9 w-9" :svg="optionIcons[`workflow_${optionConfig.id}SVG`]" />
                        <Transition name="fade">
                            <div v-if="optionDrawerMenuWidth > DRAWER_MINIMUM_WIDTH" class="truncate pr-2 text-left">{{ $t(`workflow_${optionConfig.id}.label`) }}</div>
                        </Transition>
                    </SimpleButton>
                </template>
            </div>
        </div>
    </HorizontalTransition>
</template>
