<script lang="ts" setup>
// Dependencies - Vendor
import { ref, useId, watchEffect } from 'vue';

// Interfaces/Types - Color Identifier
type ColorId = 'caution' | 'negative' | 'positive';

// Attributes, Emitted Events, Options, Properties & Slots
const { color = 'negative' } = defineProps<{ color?: ColorId }>();

// Constants
const COLORS: Record<string, { caution: string; negative: string; positive: string }> = {
    '1': { caution: '#ffd600', negative: '#ff6565', positive: '#24e2a3' },
    '2': { caution: '#ccb01d', negative: '#e54747', positive: '#1bc18a' }
};
const GRADIENT_ID: string = useId();

// Utilities - Set Color
const setColor = (key: string, id: ColorId): string => COLORS[key][id] || 'transparent';

// Reactive Variables & Watchers
const color1 = ref<string>(setColor('1', 'negative'));
const color2 = ref<string>(setColor('2', 'negative'));
watchEffect((): void => {
    color1.value = setColor('1', color);
    color2.value = setColor('2', color);
});
</script>

<template>
    <svg fill="none" height="12" viewBox="0 0 12 12" width="12">
        <rect :fill="color1" fill-opacity="0.25" height="12" rx="6" width="12" />

        <rect :fill="`url(#${GRADIENT_ID})`" height="7.5" rx="3.75" width="7.5" x="2.25" y="2.25" />

        <defs>
            <linearGradient :id="GRADIENT_ID" gradientUnits="userSpaceOnUse" x1="6" x2="6" y1="2.25" y2="9.75">
                <stop :stop-color="color1" />

                <stop offset="1" :stop-color="color2" />
            </linearGradient>
        </defs>
    </svg>
</template>
