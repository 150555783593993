<script lang="ts" setup>
// Dependencies - Vendor
import { computed } from 'vue';

// Dependencies - Framework
import { actionIcons } from '@/assets/icons';
import { useWorkbenchStore } from '@/stores/workbenchStore';

// Dependencies - Component
import SimpleButton from '@/components/SimpleButton.vue';
import SVGIcon from '@/components/SVGIcon.vue';

// Attributes, Emitted Events, Options, Properties & Slots
const emit = defineEmits<(event: 'action', id: string) => void>();

// Global State
const workbenchStore = useWorkbenchStore();

// Reactive Variables & Watchers
const toolMenuDataActionsEnabled = computed<boolean>((): boolean => workbenchStore.toolMenuDataActionsEnabled);

// UI Event Handlers - Action
const handleAddAction = (): void => emit('action', 'add');
const handleCancelAction = (): void => emit('action', 'cancel');
const handleSaveAction = (): void => emit('action', 'save');
</script>

<template>
    <div class="flex gap-x-2">
        <!-- Add Action -->
        <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option.clear" @click="handleAddAction">
            <SVGIcon class="h-9 w-9" :svg="actionIcons.addSVG" />
        </SimpleButton>

        <!-- Save Action -->
        <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option.clear" @click="handleSaveAction">
            <SVGIcon class="h-9 w-9" :svg="actionIcons.saveSVG" />
        </SimpleButton>

        <!-- Cancel Action -->
        <SimpleButton :disabled="!toolMenuDataActionsEnabled" kind="option.clear" @click="handleCancelAction">
            <SVGIcon class="h-9 w-9" :svg="actionIcons.cancelSVG" />
        </SimpleButton>
    </div>
</template>
