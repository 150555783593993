// Dependencies - Vendor
import { type Ref, ref, watchEffect } from 'vue';

// Dependencies - Framework
import { buildFetchError } from '@datapos/datapos-share-core';
import { TIMEOUT_MS } from '@/globals';

// Interfaces/Types - Query
export interface Query<T> {
    execute: () => Promise<T>;
    inValidate: () => void;
    isStale: Ref<boolean>;
}

// Constants
const STALE_TIME = 300000; // Calculated as 5 mins * 60 secs * 1000 ms;

// Helper - Use Query
// eslint-disable-next-line max-lines-per-function
export const useQuery = <T>(options: { url: string; headers?: HeadersInit; staleTime?: number }): Query<T> => {
    // Non-Reactive Variables
    let currentRequest: Promise<T> | undefined;
    let data: T | undefined;
    let staleTimer: number | undefined;
    const controller = new AbortController();
    let timeoutTimer: number | undefined;

    // Reactive Variables & Watchers
    const isStale = ref<boolean>(true);
    watchEffect((onInvalidate): void => onInvalidate((): void => inValidate()));

    // Utilities - Execute
    const execute = async (force = false): Promise<T> => {
        if (data && !isStale.value && !force) return data;

        if (currentRequest) return currentRequest;

        const requestPromise = (async (): Promise<T> => {
            data = undefined;
            timeoutTimer = window.setTimeout((): void => controller.abort(), TIMEOUT_MS);
            const response = await fetch(options.url, { headers: options.headers, signal: controller.signal });
            clearTimeout(timeoutTimer);
            if (!response.ok) throw await buildFetchError(response, 'Failed to execute query.', 'useQuery.execute.1');
            data = (await response.json()) as T;
            isStale.value = false;
            staleTimer = window.setTimeout((): boolean => (isStale.value = true), options.staleTime || STALE_TIME);
            return data;
        })();

        try {
            return await requestPromise;
        } finally {
            if (currentRequest === requestPromise) {
                currentRequest = undefined;
            }
        }
    };

    // Utilities - InValidate
    const inValidate = (): void => {
        if (staleTimer) {
            clearTimeout(staleTimer);
            staleTimer = undefined;
        }
        if (timeoutTimer) {
            clearTimeout(timeoutTimer);
            timeoutTimer = undefined;
        }
        controller.abort();
    };

    // Exposures
    return { execute, inValidate, isStale };
};
