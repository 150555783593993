<script lang="ts" setup>
// Dependencies - Vendor
import { ref, watchEffect } from 'vue';

// Dependencies - Framework
import { buildComponentClasses, type ComponentTypeConfig } from '@/globals';

// Interfaces/Types - Simple Button Kind
export type SimpleButtonKind =
    | 'accordian'
    | 'alert'
    | 'card'
    | 'default'
    | 'default.caution'
    | 'default.negative'
    | 'default.neutral'
    | 'default.positive'
    | 'dropdown'
    | 'icon'
    | 'link'
    | 'link.negative'
    | 'option'
    | 'option.clear'
    | 'option.neutral'
    | 'option.positive'
    | 'option.caution'
    | 'tab';

// Attributes, Emitted Events, Options, Properties & Slots
const { kind = 'default' } = defineProps<{ kind?: SimpleButtonKind }>();
defineSlots<{ default(): unknown }>();

// Constants
const COMMON_DISABLED_CLASSES = 'disabled:pointer-events-none disabled:opacity-50';
const COMMON_FOCUS_CLASSES = 'ring-offset-2 focus:outline-none focus:ring-2 focus:ring-neutral-300 focus:ring-offset-surface-light dark:focus:ring-neutral-600 dark:focus:ring-offset-surface-dark';
const CONFIGURATION: ComponentTypeConfig = {
    accordian: {
        base: `${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': 'bg-transparent hover:bg-transparent active:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:active:bg-neutral-700'
        }
    },
    alert: {
        base: `h-8 rounded border border-divider-light px-4 text-sm font-medium dark:border-divider-dark ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-transparent hover:bg-neutral-200 active:bg-backdrop-light dark:bg-transparent dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    card: {
        base: ``, // TODO
        variants: { '': 'hover:bg-[#f7fbfe] active:bg-neutral-100 dark:hover:bg-neutral-800 dark:active:bg-neutral-700' }
    },
    dropdown: {
        base: `h-10 rounded px-3 ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-backdrop-light hover:bg-neutral-200 active:bg-backdrop-light dark:bg-backdrop-dark dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    icon: {
        base: `rounded text-sm font-medium ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: { '': 'bg-transparent hover:bg-neutral-200 active:bg-backdrop-light dark:bg-transparent dark:hover:bg-neutral-700 dark:active:bg-backdrop-dark' }
    },
    link: {
        base: `focus:outline-none ${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': 'bg-transparent font-medium text-[#4b8bbe] hover:text-blue-800 hover:no-underline active:text-blue-600 dark:bg-transparent dark:text-blue-300 dark:hover:text-blue-400 dark:active:text-blue-300',
            negative: 'bg-transparent text-red-600 hover:text-red-800 hover:no-underline active:text-red-600 dark:bg-transparent dark:text-red-300 dark:hover:text-red-400 dark:active:text-red-300'
        }
    },
    option: {
        base: `flex h-10 items-center rounded px-0.5 ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: {
            '': 'bg-neutral-100 hover:bg-neutral-300 active:bg-neutral-100 data-[selected=true]:bg-slate-300 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:active:bg-neutral-700 dark:data-[selected=true]:bg-slate-600',
            caution:
                'bg-[#f9f2b2] hover:bg-amber-300 active:bg-amber-200 data-[selected=true]:bg-slate-300 dark:bg-amber-300 dark:text-neutral-900 dark:hover:bg-amber-400 dark:active:bg-amber-300 dark:data-[selected=true]:bg-slate-600',
            clear: 'bg-transparent hover:bg-neutral-300 active:bg-neutral-100 data-[selected=true]:bg-slate-300 dark:bg-transparent dark:hover:bg-neutral-600 dark:active:bg-neutral-700 dark:data-[selected=true]:bg-slate-600',
            neutral:
                'bg-[#f9fcfe] border border-[#e9f1f8] hover:bg-sky-300 active:bg-sky-200 data-[selected=true]:bg-slate-300 dark:bg-sky-300 dark:text-neutral-900 dark:hover:bg-sky-400 dark:active:bg-sky-300 dark:data-[selected=true]:bg-slate-600',
            positive:
                'bg-[#d1f7d1] hover:bg-green-300 active:bg-green-200 data-[selected=true]:bg-slate-300 dark:bg-green-300 dark:text-neutral-900 dark:hover:bg-green-400 dark:active:bg-green-300 dark:data-[selected=true]:bg-slate-600'
        }
    },
    tab: {
        base: `focus:outline-none ${COMMON_DISABLED_CLASSES}`,
        variants: {
            '': ''
        }
    },
    default: {
        base: `h-9 justify-center rounded text-sm font-medium ${COMMON_DISABLED_CLASSES} ${COMMON_FOCUS_CLASSES}`,
        variants: {
            '': 'bg-neutral-200 hover:bg-neutral-300 active:bg-neutral-200 dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:active:bg-neutral-700',
            caution: 'bg-[#f4e0a1] hover:bg-amber-300 active:bg-amber-200 dark:bg-amber-300 dark:text-neutral-900 dark:hover:bg-amber-400 dark:active:bg-amber-300',
            negative: 'bg-[#f9d0d0] hover:bg-red-300 active:bg-red-200 dark:bg-red-300 dark:text-neutral-900 dark:hover:bg-red-400 dark:active:bg-red-300',
            neutral: 'bg-[#e9f1f8] border border-[#c8dcee] hover:bg-[#c8dcee] active:bg-sky-200 dark:bg-sky-300 dark:text-neutral-900 dark:hover:bg-sky-400 dark:active:bg-sky-300',
            positive: 'bg-[#d1f7d1] hover:bg-green-300 active:bg-green-200 dark:bg-green-300 dark:text-neutral-900 dark:hover:bg-green-400 dark:active:bg-green-300'
        }
    }
};

// Reactive Variables & Watchers
const classes = ref<string>('');
watchEffect((): void => {
    classes.value = buildComponentClasses(CONFIGURATION, kind);
});
</script>

<template>
    <button :class="classes" type="button">
        <slot />
    </button>
</template>
